@use 'shared/scss' as *;

.button-s {
  width: fit-content;
  font-size: 14px;
  line-height: 14px;
  font-family: 'overpass', sans-serif;
  display: flex;
  flex-direction: row;
  background-color: $black;
  color: $white;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: $borderRadiusSmall;
  border: solid 2px;
  transition-duration: 0.5s;

  .icon {
    width: 18px;
    height: 18px;
  }

  &.label {
    padding: 16px 25px 16px 18px;

    .icon {
      margin: 0px 8px;
    }
  }

  &.black {
    background-color: $black;
    border-color: $black;
  }

  &.glass {
    background-color: transparent;
    border-color: $grey-darker;
  }

  &.pink {
    background-color: $pink;
    border-color: $pink;

    &:hover {
      opacity: 0.7;
    }
  }

  &.white {
    color: $white;

    &:hover {
      color: $green;
    }
  }

  &.green {
    color: $green;

    &:hover {
      opacity: 0.7;
    }
  }

  &.green-full {
    background-color: $green;
    color: black;

    &:hover {
      opacity: 0.7;
    }
  }

  &.red {
    color: $red;

    &:hover {
      opacity: 0.7;
    }
  }

  &.flat {
    padding: 0;
    background-color: transparent;
    border: hidden;

    &.pink {
      color: $pink;
    }
  }

  .round {
    padding: 16px 46px 12px 46px;
    border: solid 2px $black;
    border-radius: 30px;
    transition-duration: 1s;
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &.register {
    background: rgba($white, 0.1);
    width: 100%;
    max-width: 360px;
    color: $grey-light;
    padding: 20px 30px;
    justify-content: flex-start;

    .icon {
      width: 48px;
      height: 48px;
      margin: 0 24px 0 0;
    }

    .button-label {
      text-align: left;
      width: 70%;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.button-label {
  transform: translateY(2px);
}

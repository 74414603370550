@use 'shared/scss' as *;

.welcome-popup {
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 24px;
    margin-bottom: $popup-element-margin-bottom;
    text-align: center;

    @include tablet {
      font-size: 32px;
      text-align: left;
    }
  }

  &__desc {
    font-size: 16px;
    margin-bottom: $popup-element-margin-bottom;
    text-align: center;

    @include tablet {
      text-align: left;
    }
  }

  &__button {
    align-self: center;

    @include tablet {
      align-self: flex-end;
    }
  }
}

@use 'shared/scss' as *;

.add-edit-location {
  &__select--select {
    @include tablet {
      @include formFieldGroupLine();
      align-items: flex-start;
    }
  }

  > form {
    > .form-checkbox {
      label {
        display: inline-flex;
      }
    }
    > .form-multiselect {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      > * {
        margin-right: 32px;
      }

      > label {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  &__textbox--description {
    @include formFieldGroupLine();

    > *:not(:last-child) {
      margin-right: 10px;
    }

    > span {
      align-self: flex-end;
      height: 39px;
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
    }

    > .form-textbox {
      max-width: 146px;

      > label {
        margin-right: -160px;
      }
    }
  }

  &__heating--description {
    font-size: 16px;
    line-height: 24px;
    color: $grey-light;
    margin-bottom: $form-field-margin-bottom;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;

    .button-p.black {
      color: $grey-medium;

      &:hover {
        color: $white;
      }
    }
  }
}

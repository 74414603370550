@use 'shared/scss' as *;

.form-error {
  padding-left: 12px;
  font-size: 14px;
  line-height: 20px;
  color: $red-third;
  text-align: left;
  position: relative;
  font-weight: 400;
  margin-top: 4px;

  &::before {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 2L8.36396 8.36396M8.36396 2L2 8.36396' stroke='%23FF4040' stroke-width='2.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    position: absolute;
    width: 8px;
    height: 8px;
    left: 0;
    top: 5px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.price-below {
    position: absolute !important;
  }
}

@use 'shared/scss' as *;

.location-switch-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  color: $white;

  > .icon {
    width: 30px;
    height: 30px;
    margin-left: 30px;
    transition-duration: 1s;

    &:hover {
      transform: rotateZ(90deg);
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid $white;
    flex-grow: 1;
    justify-content: flex-start;
    text-align: left;
    transition-duration: 0.5s;
    cursor: pointer;
    background-color: rgba($white, 0.1);
    max-width: 530px;

    &.invitation {
      max-width: initial;
    }

    > .icon {
      width: 31px;
      height: 27px;
      margin-right: 17px;
      top: 8px;
    }

    &:hover {
      opacity: 0.7;
      border-color: $white;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;

    > h4 {
      color: $grey-title;
      margin: 0;
      font-size: 24px;
      line-height: 28px;
      font-weight: normal;
      text-align: left;
      font-family: energi;
    }

    > p {
      margin: 0;
      font-size: 12px;
      line-height: 18px;
      color: $grey-subtitle;
    }

    > span {
      font-size: 12px;
      line-height: 18px;
      color: $green;
    }
  }

  &__invitation {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 16px;

    > button:first-child {
      margin-right: 32px;
    }
  }

  &.active {
    color: $black;

    > .location-switch-item__wrapper {
      border-color: $green;
      pointer-events: none;
      background-color: $green;

      .location-switch-item__content {
        > h4 {
          color: $black;
        }
        > p {
          color: rgba($black, 0.7);
        }
        span {
          color: rgba($black, 0.7);
        }
      }
    }

    .location-switch-item__content > span {
      opacity: 0.7;
      color: $white;
    }
  }

  &.invitation {
    > .hub__wrapper {
      cursor: initial;
      flex-wrap: wrap;

      &:hover {
        opacity: 1;
        border-color: initial;
      }
    }
  }
}

@use 'shared/scss' as *;

.graph-chart {
  padding: 0;
  width: 100%;
  min-height: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    width: 95%;
  }

  & > div[data-highcharts-chart] {
    overflow: visible !important;
  }

  &__error {
    color: $white;
    font-size: 18px;
  }
}

@use 'shared/scss' as *;

.menu {
  grid-area: mobilemenu;
  width: 100%;
  display: none;
  align-items: center;
  justify-content: flex-start;
  position: absolute;

  &--visible {
    display: flex;
  }

  @include laptop {
    display: block;
    position: fixed;
    top: 70px;
    bottom: 0;
    width: 240px;
    grid-area: leftmenu;
  }

  &__drawer {
    display: flex;
    flex-direction: row;
    position: fixed;
    left: 0;
    top: 70px;

    background: rgba(0, 0, 0, 0.8);
    height: 100%;
    min-height: 100vh;
    margin-top: -70px;
    width: 100%;

    z-index: 99;

    @include laptop {
      background-color: transparent;
      width: fit-content;
      top: 70px;
      bottom: 0;
      margin-top: 0;
      height: auto;
      min-height: auto;
      width: 240px;
    }
  }

  &__exit {
    width: 100%;
    height: 100%;

    @include laptop {
      display: none;
    }
  }

  &__content {
    background-color: $black-second;
    width: fit-content;
    min-width: 250px;
    animation: popmenu linear 0.3s;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include laptop {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: 240px;
      animation: none;
      border-bottom: 1px solid $grey-darker;
      border-right: 1px solid $black;
    }

    @media screen and (max-height: 720px) {
      overflow-y: auto;
    }

    &--logo {
      background-color: $black;
      display: flex;
      justify-content: left;
      align-items: center;
      height: 70px;
      padding-left: 20px;
      font-size: 16px;

      .icon {
        width: 20px;
        height: 20px;
      }

      @include laptop {
        display: none;
      }
    }
  }

  &__logout {
    position: inherit;
    bottom: 0;
    left: 0;
    border: none;

    @include laptop {
      display: block;
      min-height: 69px;
    }
  }

  &__copyright {
    display: flex;
    justify-content: right;
    color: $grey;
    font-size: 12px;
    padding: 22px 24px;

    > span {
      cursor: default;
    }
  }
}

@keyframes popmenu {
  from {
    transform: translate(-100%, 0);
  }

  to {
    transform: translate(0, 0);
  }
}

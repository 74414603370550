@use 'shared/scss' as *;

.locationswitch {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 27px;

  > .icon:first-child {
    display: none;
  }

  &__name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 6px;

    &--missing {
      font-size: 16px;
      line-height: 24px;
      color: rgba($white, 0.5);
      font-weight: 400;
    }

    &--hub {
      font-size: 14px;
      line-height: 21px;
      color: $white;
      font-weight: bold;
    }

    &--shared {
      font-size: 10px;
      line-height: 15px;
      color: rgba($white, 0.7);
    }
  }
  &__notifications {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $pink;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: $white;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    margin-right: 10px;
    margin-left: 10px;

    @include laptop {
      margin-left: 0;
    }
  }

  @include laptop {
    padding: 0 27px;

    > .icon:first-child {
      display: block;
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }

    > .icon:last-child {
      display: block;
      width: 18px;
      height: 18px;
    }
  }
}

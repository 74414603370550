@use 'shared/scss' as *;

.datepicker-buttons {
  display: flex;
  justify-content: space-between;
}

.form-datepicker {
  width: 100%;
  margin-bottom: $form-field-margin-bottom;
  position: relative;

  &__date-clear {
    position: absolute;
    top: 13px;
    right: 5px;
    width: 32px;
    height: 32px;
    transform: translate(0, 50%);
    background-color: transparent;
  }

  &__input {
    cursor: pointer;

    .form-textbox {
      margin-bottom: 0;

      &__input {
        > input {
          cursor: pointer;
        }
      }
    }
  }

  &__with-interval {
    margin-top: 25px;
    .form-textbox {
      margin-bottom: 35px;

      &__input {
        cursor: pointer;
        > input {
          cursor: pointer;
          pointer-events: none;
        }
      }
    }
  }

  &__calendar {
    width: 100%;
    max-width: 340px;
    margin: auto;
    height: 500px;
  }

  @include tablet {
    max-width: 266px;
  }
}

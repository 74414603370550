@use 'shared/scss' as *;

.header {
  grid-area: header;
  width: 100%;
  position: relative;
  z-index: 10;
  padding: 0 15px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: 'logo';
  background-color: $black-lighter;

  @include laptop {
    background-color: $black;
  }

  .button-s.glass {
    border: none;
    z-index: 2;
    padding: 0;

    @include laptop {
      display: none;
    }
  }

  &.login {
    background-color: $black-second;
    border-bottom: 1px solid $grey-darker;
  }

  &.registration {
    grid-template-areas: 'logo' 'headermenu';
    grid-template-rows: auto;
    padding-top: 15px;

    .header-menu__links {
      display: flex;
      width: 100%;
      padding: 0;
      justify-content: center;
    }

    .logo {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    @include tablet-small {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: 'logo headermenu';
      padding-top: 0;

      .logo {
        justify-content: start;
      }

      .header-menu__links {
        padding: 0 27px 0 0;
        justify-content: end;
      }
    }

    @include laptop {
      .logo {
        padding-left: 27px;
        margin: auto;
      }

      .header-menu__links {
        padding-right: 27px;
        justify-content: end;
      }
    }
  }

  &.portal {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'mobilemenu headermenu';

    .logo {
      display: none;
    }

    @include laptop {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: 'logo headermenu';
      position: fixed;
      height: 70px;
      z-index: 99;

      .logo {
        display: block;
      }
    }
  }

  @include tablet {
    padding: 0 40px 0 24px;
  }
}

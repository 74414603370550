// FORMS
$form-field-margin-right: 18px;
$form-field-margin-top: 16px;
$form-field-margin-bottom: 32px;

// POPUPS
$popup-element-margin-bottom: 32px;

// CONTAINERS
$borderRadiusBig: 24px;
$borderRadius: 12px;
$borderRadiusSmall: 5px;
$borderRadiusSmallest: 4px;
$info-box-margin-bottom: 24px;

// ANIMATION
$transition-default: 0.3s linear;

@use 'shared/scss' as *;

.footer {
  color: $grey;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 15px;
  grid-area: footer;
  background-color: $black-lighter;

  &.login {
    margin-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $black-second;
  }

  &__group_links {
    z-index: 0;
  }

  &__links {
    color: $grey;

    &:not(:first-child) {
      margin-left: 10px;
    }

    @include laptop {
      transition-duration: 0.5s;

      &:hover {
        color: $white;
      }
    }
  }

  @include mobile-only-xs {
    font-size: 10px;
  }

  @include tablet {
    padding: 0 40px;

    > span {
      margin-left: 40px;
    }
  }

  @include laptop {
    padding: 28px 60px;
    min-height: 70px;
    z-index: 1;
    bottom: 0;
    left: 0;
    margin-left: 240px;

    > span {
      cursor: default;
    }
  }
}

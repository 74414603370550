@use 'shared/scss' as *;

.extended-warranty-warning {
  &__description {
    margin: 32px 0;
    color: rgba($white, 0.6);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;

    > a {
      color: $green;
      text-decoration: underline;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__popup {
    padding-top: 34px;

    .popup__close {
      display: none;
    }
  }
}

body {
  overflow-y: auto;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: $green rgba($white, 0.08);
}

body::-webkit-scrollbar {
  width: 12px;
  background-color: rgba($white, 0.08);
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba($black, 0.3);
  border-radius: 10px;
  background-color: rgba($white, 0.08);
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba($green, 0.3);
  background-clip: padding-box;
  background-color: $green;
}

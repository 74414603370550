@use 'shared/scss' as *;

@mixin pointer {
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg width='34' height='14' viewBox='0 0 34 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M34 0H0L11.3431 11.3431C14.4673 14.4673 19.5327 14.4673 22.6569 11.3431L34 0Z' fill='%23444444'/%3E%3C/svg%3E");
  position: absolute;
  bottom: -16px;
  height: 17px;
  width: 34px;
  background-repeat: no-repeat;
}

.site_preferences {
  $this: &;
  padding: 20px;
  background-color: $grey-dark-second;
  border-radius: 8px;
  position: relative;
  z-index: 10;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: $grey;
  //   width: 380px;
  width: 100%;

  &__wrapper {
    position: relative;
    max-width: 26px;
  }

  &::before {
    @include pointer();
    left: calc(100% - 185px);
  }

  @include tablet {
    &::before {
      @include pointer();
      left: calc(100% - 210px);
    }

    width: 380px;
  }

  @include laptop {
    &::before {
      @include pointer();
      left: calc(50% - 47px);

      @include mobile-only {
        left: unset;
        right: 3px;
      }
    }

    width: 380px;
  }

  &__box {
    width: calc(100% - 40px);
    margin: 0 20px;
    max-width: 100vw;
    position: fixed;
    top: 70px;
    // left: calc((100% - 420px));
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease-out;
    z-index: 9;

    @include tablet {
      left: calc((100% - 420px));
    }

    @include laptop {
      width: 320px;
      max-width: 100vw;
      position: absolute;
      margin: 0;

      top: unset;
      left: unset;

      &.bottom {
        left: calc(-160px + 50%);
        margin-top: 35px;
      }
    }

    &.active {
      opacity: 1;
      visibility: initial;
      transition: 0.3s ease-in;
    }

    &.bottom {
      #{$this}::before {
        transform: rotate(180deg);
        top: -17px;
      }
    }
  }

  &__content {
    &__title {
      font-size: 18px;
      margin-bottom: 20px;
      color: $white;
    }

    &__form {
      &__label__opened {
        color: $green;
      }

      .form-select {
        margin-bottom: 10px !important;
      }

      .form-select__control {
        border-radius: 8px !important;
        background-color: $grey-site-preferences !important;
        margin-bottom: 5px;
        margin-top: 4px;
        padding: 8px 14px !important;

        &--menu-is-open {
          border-color: $green !important;
        }

        .form-select__indicator-separator {
          display: none;
        }
      }

      .form-select__menu {
        border: 1px solid $grey-site-preferences;
        padding: 10px;
        background-color: $grey-site-preferences !important;

        &-list {
          width: 100%;
          background-color: $grey-site-preferences !important;
          gap: 6px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 6px;
        }
      }

      .form-select__option {
        background-color: $grey-site-preferences !important;
        padding: 2px 8px !important;
        width: 100%;
        align-items: center;
        display: flex;
        min-height: 29px !important;
        font-size: 16px;
      }

      .form-select__option:hover {
        background-color: $grey !important;
        border-radius: 8px;
      }

      .form-country__option--flag {
        margin-left: 10px;
        border-radius: 2px;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  .site_preferences__content__form__language {
    .form-select__option {
      display: flex;
      align-items: center;
      min-height: 30px;
      padding: 2px 8px !important;
      line-height: 16px;
    }

    .form-select__menu-list {
      padding-top: 0 !important;
    }

    .form-select__menu {
      border-radius: 8px !important;
    }
  }
}

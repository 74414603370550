@use 'shared/scss' as *;

.form-textbox {
  $root: &;

  width: 100%;
  margin-bottom: $form-field-margin-bottom;

  > label {
    @include label();
    color: $grey-fifth;
  }

  .icon {
    width: 18px;
    height: 18px;
  }

  &__input {
    width: 100%;
    border-radius: $borderRadiusSmallest;
    border: 1px solid rgba($white, 0.6);
    background-color: $grey-dark-third;
    padding: 8px 16px;

    input {
      font-size: 16px;
      line-height: 25px;
      color: $white;
      cursor: auto;
      outline: none;
      padding-top: 0;
      width: 100%;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-text-fill-color: $white;
        -webkit-box-shadow: 0 0 0px 1000px #333 inset;
        box-shadow: 0 0 0px 1000px #333 inset;
      }

      &::placeholder {
        color: rgba($white, 0.5);
        opacity: 0.5;
      }

      &:disabled {
        cursor: initial;
      }

      // DISABLING BROWSER ARROWS FOR INPUT TYPE NUMBER
      &[type='number']::-webkit-outer-spin-button,
      &[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type='number'] {
        -moz-appearance: textfield;
      }
    }

    &.has-info {
      position: relative;
      padding-right: 35px;

      .info-popup__wrapper {
        position: absolute;
        right: 11px;
        top: 11px;
        margin-top: 9px;

        .info-popup__box {
          width: 320px !important;
          max-width: 100vw;
          position: absolute;
          opacity: 0;
          visibility: hidden;
          transition: 0.3s ease-out;

          @include mobile-only {
            width: 300px !important;
            max-width: 90vw !important;
          }

          &.active {
            opacity: 1;
            visibility: initial;
            transition: 0.3s ease-in;
          }

          &.top {
            left: calc(-180px + 50%) !important;
            bottom: 25px !important;

            @include mobile-only {
              left: calc(-300px + 50%) !important;
              bottom: 25px !important;
            }

            @include mobile-only-xxs {
              left: calc(-280px + 50%) !important;
              bottom: 25px !important;
            }
          }
        }
      }

      .icon {
        position: absolute;
        right: 11px;
        top: 50% !important;
        margin-top: -9px;
        width: 18px;
        height: 18px;

        @include mobile-only-xxs {
          right: 2px;
        }
      }
    }
  }

  &.disabled {
    #{$root}__input {
      background-color: unset;

      input {
        opacity: 0.7;
      }
    }
  }

  &.error {
    #{$root}__input {
      border-color: $red-third;
    }
  }

  &.hidden {
    display: none;
  }

  .is-required {
    padding-left: 5px;
  }
}

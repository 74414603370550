@use 'shared/scss' as *;

.accept-invite {
  > span {
    display: block;
    margin-bottom: $form-field-margin-bottom;
    font-size: 16px;
    line-height: 24px;
    color: $grey-subtitle;
  }

  &__content {
    margin-top: $form-field-margin-top;
    margin-bottom: $form-field-margin-bottom;
    padding: 24px;

    border-radius: $borderRadius;
    background: rgba($white, 0.08);

    &--data {
      display: flex;
      flex-direction: column;
      color: $white;
      margin-bottom: $form-field-margin-bottom;

      > span:first-child {
        font-size: 20px;
        line-height: 31px;
        margin-bottom: 16px;
      }

      .description {
        color: $grey-subtitle;
        line-height: 22px;
        margin: 0;
      }
    }

    &--buttons {
      display: flex;
      justify-content: space-between;
    }

    > .icon {
      height: 48px;
      width: 48px;
      margin-right: 24px;
    }
  }
}

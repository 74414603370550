@use 'shared/scss' as *;

.info-popup {
  $this: &;

  padding: 24px;
  background-color: $grey-dark-second;
  border-radius: 8px;
  position: relative;
  z-index: 4;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: $grey;

  &__wrapper {
    position: relative;
    max-width: 26px;

    > *:not(.info-popup__box) {
      cursor: pointer;
    }
  }

  @include tablet {
    &::before {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='34' height='14' viewBox='0 0 34 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M34 0H0L11.3431 11.3431C14.4673 14.4673 19.5327 14.4673 22.6569 11.3431L34 0Z' fill='%23444444'/%3E%3C/svg%3E");
      position: absolute;
      left: calc(50% - 17px);
      bottom: -16px;
      height: 17px;
      width: 34px;
      background-repeat: no-repeat;

      @include mobile-only {
        left: unset;
        right: 3px;
      }
    }
  }

  &__box {
    width: calc(100% - 40px);
    margin: 0 20px;
    max-width: 100vw;
    position: fixed;
    top: 95px;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease-out;
    z-index: 9;

    @include tablet {
      width: 320px;
      max-width: 100vw;
      position: absolute;
      margin: 0;
      top: unset;
      left: unset;
    }

    &.active {
      opacity: 1;
      visibility: initial;
      transition: 0.3s ease-in;
    }

    @include tablet {
      &.top {
        left: calc(-160px + 50%);
        bottom: 40px;
      }

      &.bottom {
        left: calc(-160px + 50%);
        margin-top: 17px;

        #{$this}::before {
          transform: rotate(180deg);
          top: -17px;
        }
      }

      &.left {
        margin-right: 17px;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);

        #{$this}::before {
          transform: rotate(270deg);
          bottom: unset;
          top: calc(50% - 9px);
          right: -25px;
          left: unset;
        }
      }

      &.right {
        margin-left: 17px;
        left: 100%;
        top: calc(50% - 60px);

        #{$this}::before {
          transform: rotate(90deg);
          bottom: unset;
          top: calc(61% - 17px);
          left: -25px;
          right: unset;
        }
      }
    }
  }

  &__title {
    margin-bottom: 20px;
    color: $white;
  }
}

@use 'shared/scss' as *;

.expandablemenu {
  background-color: $black-second;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: $grey;

  .menubutton {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 16px 24px;
    border: none;
    overflow: hidden;
    text-align: left;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      opacity: 0.5;
    }

    @include laptop {
      &:hover .menubutton__title {
        color: $white;
      }
    }

    &.active {
      color: $white;
      background-color: $grey-darker;

      .icon {
        opacity: 1;
      }
    }

    &.disabled {
      opacity: 0.3;
      color: $grey-second;
      pointer-events: none;
      cursor: none;
    }
  }

  ul {
    list-style: none;
  }

  &.active {
    background-color: $grey-darker;
  }

  .submenubuttons {
    .submenubutton {
      width: 100%;
      height: 100%;
      color: inherit;
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 16px 24px 16px 52px;

      li::before {
        content: '';
        background-color: $green-darker;
        margin-right: 5px;
        margin-bottom: 3px;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        display: inline-block;
      }

      &.active {
        color: $white;

        li::before {
          background-color: $green;
        }
      }

      &.disabled {
        opacity: 0.3;
        color: $grey-second;
        pointer-events: none;
        cursor: none;
      }

      @include laptop {
        &:hover .submenubutton__title {
          color: $white;
        }
      }
    }
  }
}

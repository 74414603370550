@use 'shared/scss' as *;

.form-select-datepicker {
  $this: &;

  width: 100%;
  margin-bottom: $form-field-margin-bottom;

  label {
    @include label();
    color: rgba($white, 0.35);
  }

  &:focus {
    outline: none;
  }

  &__control {
    position: relative !important;
    z-index: 2 !important;
    width: 100% !important;
    height: 41px !important;
    padding: 8px 12px 8px 20px !important;
    color: $white !important;
    background-color: $black-light !important;
    border: none !important;
    border-radius: $borderRadius !important;
    font-size: 16px !important;
    outline: none !important;
    appearance: none !important;
    box-shadow: none !important;
    align-content: center !important;
    cursor: pointer !important;

    &--menu-is-open {
      #{$this}__arrow-down {
        transform: rotate(-180deg);
      }
    }

    &::-ms-expand {
      display: none;
    }
  }

  &__value {
    width: 100%;
  }

  &__value-container {
    padding: 0 !important;
  }

  &__indicator-separator {
    display: none !important;
  }

  &__arrow-down {
    position: absolute;
    top: 16px;
    right: 20px;
    z-index: 1;
    transition: transform 0.3s;

    path {
      stroke: $white;
    }
  }

  &__single-value {
    color: $white !important;
  }

  &__option {
    background-color: $grey-dark-third !important;
    color: $white !important;
    min-height: 41px;
    padding: 16px 30px 12px 12px !important;

    &:hover {
      background-color: rgba($white, 0.6) !important;
      cursor: pointer;
    }
  }

  &__menu {
    z-index: 10;
    border-radius: $borderRadius !important;
    background-color: $grey-dark-third !important;
    margin: 0 !important;
    z-index: 3 !important;
  }

  &__menu-list {
    border-radius: $borderRadius !important;
    background-color: $grey-dark-third !important;
    padding: 0 !important;
    max-height: 205px !important;

    @include scrollbar();
  }

  &--is-disabled {
    .form-select__control {
      color: rgba($white, 0.5) !important;
    }

    #{$this}__placeholder {
      color: rgba($white, 0.5) !important;
      opacity: 0.5;
    }

    #{$this}__single-value {
      color: rgba($white, 0.5) !important;
    }
  }
}

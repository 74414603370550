@use 'shared/scss' as *;

.logo {
  grid-area: logo;
  width: fit-content;

  a {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .icon {
    width: 22px;
    height: 30px;
  }

  &__name {
    font-family: 'energi', 'overpass', sans-serif;
    font-size: 16px;
    color: $white;
    margin-left: 5px;
  }

  &.solo {
    margin: auto;
  }

  @include mobile-only-xs {
    height: 28px;

    a,
    .icon {
      height: 28px;
    }
  }

  @include laptop {
    margin: auto auto auto 0;

    &.solo {
      margin: auto;
    }
  }
}

@use 'shared/scss' as *;

.button-p {
  width: fit-content;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  font-family: 'overpass', sans-serif;
  padding: 16px 24px 16px 24px;
  border: solid 2px $black;
  border-radius: 30px;
  transition-duration: 0.5s;
  &:hover {
    opacity: 0.7;
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &.black {
    border-color: $black;
    color: $black;

    &.full {
      background-color: $black;
      color: $white;
      border: none;
    }

    &.link {
      padding: 0;
      border: none;
    }
  }

  &.green {
    border-color: $green;
    color: $green;

    &.full {
      background-color: $green;
      color: $black;
      border: none;
    }

    &.link {
      padding: 0;
      border: none;
    }
  }

  &.pink {
    border-color: $pink;
    color: $pink;

    &.full {
      background-color: $pink;
      color: $black;
      border: none;
    }

    &.link {
      padding: 0;
      border: none;
    }
  }

  &.red {
    border-color: $red-third;
    color: $red-third;

    &.full {
      background-color: $red-third;
      color: $black;
      border: none;
    }

    &.link {
      padding: 0;
      border: none;
    }
  }

  &.blue {
    border-color: $blue-second;
    color: $blue-second;

    &.full {
      background-color: $blue-second;
      color: $black;
      border: none;
    }

    &.link {
      padding: 0;
      border: none;
    }
  }

  &.white {
    border-color: $white;
    color: $white;

    &.full {
      background-color: $white;
      color: $black;
      border: none;
    }

    &.link {
      padding: 0;
      border: none;
    }

    &.underline {
      text-decoration: underline;
    }
  }

  &.square {
    color: $white;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 5px;
  }
  &.square-full {
    color: $white;
    background: #f6393f;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 5px;
  }
  &.square-full-green {
    color: $black;
    background: $green;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 5px;
  }
  &.square-green {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    border-color: $green;
    color: $green;
  }
}

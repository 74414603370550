@use 'shared/scss' as *;

.form-country {
  &__value {
    display: flex;
    align-items: center;
    padding: 0px !important;
  }

  &__label__opened {
    color: $green;
  }

  &__label__closed {
    margin-bottom: 4px;
  }

  &__wrapper {
    &__opened {
      height: 350px;
    }
  }

  &__option--content {
    display: flex;
    height: 16px;
  }

  .form-select__single-value {
    padding-right: 6px;
    line-height: 16px;
    height: 16px;
    padding-top: 1px;
  }

  .form-select__control {
    padding: 0 !important;
  }

  .form-select__menu {
    border-radius: 8px !important;
  }
}

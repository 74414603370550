@use 'shared/scss' as *;

.menubutton {
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;

  display: flex;
  justify-content: left;
  align-items: center;

  background-color: $black-second;
  color: $grey;
  border: none;
  transition-duration: 0.5s;
  overflow: hidden;

  a {
    width: 100%;
    height: 100%;
    color: inherit;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 16px 24px;
  }

  .icon {
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &.col {
    a {
      flex-direction: row;

      .icon {
        margin-bottom: 0;
        margin-right: 8px;
      }
    }
  }

  &.row {
    a {
      flex-direction: row;
      justify-content: left;
    }
  }

  &.row &__title {
    margin-left: 8px;
  }

  @include laptop {
    &__title {
      transition-duration: 0.5s;
    }

    &:hover .menubutton__title {
      color: $white;
    }
  }

  &.active {
    color: $white;
    background-color: $grey-darker;

    .icon {
      opacity: 1;
    }
  }

  &.disabled {
    opacity: 0.3;
    color: $grey-second;
    pointer-events: none;
    cursor: none;
  }
}

@use 'shared/scss' as *;

$calendar-padding: 24px;

.calendar {
  background: $white;
  display: inline-block;
  width: 100%;
  border-radius: 20px;
  font-family: 'Overpass', sans-serif;

  &__title {
    font-size: 24px;
    font-weight: 400;
    line-height: 125%;
  }

  &__top {
    padding: $calendar-padding;
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    .icon {
      width: 34px;
      height: 34px;
      background-color: rgba($white, $alpha: 0.1);
      border-radius: 50%;
      top: 15px;
      right: 20px;
    }
  }

  &__info {
    font-family: 'Overpass';
    font-size: 14px;
    color: $white;
    font-weight: 400;
    padding: $calendar-padding;

    &-container {
      margin: 0 20px 20px 24px;
      display: flex;

      .icon-object {
        margin-right: 18px;
      }
    }

    > span {
      cursor: pointer;
      color: $green;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    padding: $calendar-padding;

    > button {
      transition-duration: 0.5s;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  table {
    display: inline-table;
    table-layout: fixed;
    border: none;
    user-select: none;
    width: 100%;
    padding: 16px $calendar-padding;
  }

  thead {
    td {
      font-size: 16px;
      line-height: 125%;
      font-weight: 400;
      text-transform: uppercase;
      color: $white;
      text-align: center;
      cursor: pointer;
      padding-bottom: 26px;
    }
  }

  tbody {
    position: relative;
    display: inline-table;
    width: 284px;
    height: 190px;

    &:before {
      position: absolute;
      content: '';
      left: -$calendar-padding;
      top: -16px;
      right: -$calendar-padding;
      bottom: -16px;
      background-color: rgba($color: $white, $alpha: 0.08);
    }

    td {
      $cell: &;
      color: $white;
      font-size: 16px;
      line-height: 30px;
      font-weight: 500;
      position: relative;
      transition-duration: 0.5s;
      text-align: center;
      height: 30px;
      padding-top: 5px;
      cursor: pointer;

      &:hover {
        color: $white;
      }

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        inset: 0;
        border-radius: 4px;
        position: absolute;
        z-index: -1;
        opacity: 0;
      }

      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }
  }

  .current-day {
    z-index: 0;
    opacity: 1;
    &:hover {
      color: $black;

      &::before {
        opacity: 0.8;
      }
    }

    color: $black;
    font-weight: 700;
    &:before {
      background-color: $green;
      border-color: $green;
      opacity: 1;
    }
  }

  .disabled-day {
    color: $white;
    pointer-events: none;
    opacity: 0.5;
  }

  .hidden-day {
    &:hover:before {
      opacity: 0;
    }
    pointer-events: none;
  }

  .selectable-day {
    background: rgba($color: $white, $alpha: 0.08);
    border-radius: 4px;
  }

  &__selectors {
    display: flex;
    flex-direction: row;
    padding: 0 $calendar-padding;
    display: flex;
    gap: 16px;
    justify-content: space-between;
  }

  &__select {
    margin-bottom: $calendar-padding;

    > label {
      color: rgba($color: $white, $alpha: 0.35);
    }
    .form-select__control {
      padding: 10px 20px !important;
      font-size: 16px;
    }
  }

  .toolbar {
    height: 40px;
    padding: 11px 15px 12px;
    background: rgba($color: $white, $alpha: 0.1);
    border: 1px solid rgba($color: $white, $alpha: 0.6);
    border-radius: 4px;

    button {
      border: none;
      background: initial;
      outline: none;
    }

    .icon {
      width: 12px;
      height: 12px;
    }

    &__next-month {
      .icon {
        transform: rotate(180deg);
      }
    }

    &__current-date {
      color: rgba($white, 0.6);
      font-size: 16px;
      font-weight: 500;
      padding: 0 16px;
      position: relative;
      height: 100%;
      min-width: 60px;

      input[type='number'] {
        position: absolute;
        inset: -1px 1px auto auto;
        color: rgba($white, 0.6);

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        -moz-appearance: textfield;
        appearance: textfield;
        max-width: 48px;
      }
    }

    &__prev-month,
    &__next-month {
      transition-duration: 0.5s;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

@use 'shared/scss' as *;

.cookiebar {
  z-index: 2;
  position: relative;
  color: $white;
  width: fit-content;

  &__button {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 60px;
    padding: 7px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    background-color: $green;
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
    z-index: 2;

    .icon {
      width: 18px;
      height: auto;
    }

    @include tablet {
      width: 80px;
      height: 80px;
      padding: 10px;

      .icon {
        width: 20px;
      }
    }
  }

  &__form {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: $black-second;

    z-index: 1;
    height: 100%;
    width: 100%;
    max-width: 520px;
    padding: 40px 20px;
    overflow-x: hidden;
    overflow-y: scroll;

    @include tablet {
      padding: 20px;
    }

    @include laptop {
      top: 70px;
    }

    @include scrollbar($black-second, $green);

    &--close {
      height: auto;
      cursor: pointer;

      .icon {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 42px;
        height: 42px;
        transition-duration: 1s;

        &:hover {
          transform: rotateZ(90deg);
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    &:first-child {
      padding-top: $form-field-margin-bottom;
    }
  }

  &__submit {
    margin-right: $form-field-margin-right;
  }

  &__container {
    padding-bottom: 20px;
    width: 100%;

    &--title {
      font-size: 24px;
      font-weight: 700;
      line-height: 31px;
      display: block;
      margin-right: 35px;
    }

    &--desc {
      font-size: 16px;
      line-height: 24px;
      color: $grey-light;
    }

    &--link {
      text-decoration: underline;
      margin-left: 5px;
      color: $green;
    }

    &--header {
      font-size: 20px;
      line-height: 31px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  &__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: $black;
    opacity: 0.7;
  }

  .form-separator {
    margin-bottom: 20px;
  }
}

@use 'shared/scss' as *;

.page-loading {
  height: calc(100vh - 162px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: pacecontent;
  overflow: hidden;

  span {
    color: white;
    font-weight: 400;
    font-size: 16px;
    padding-left: 32px;
  }
}

.loader {
  border: 3px solid $green;
  border-top: 3px solid $black;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@use 'shared/scss' as *;

.header-menu {
  grid-area: headermenu;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  .border {
    border-right: solid 2px $grey-secondary-text;
  }

  .logout {
    margin-top: 5px;
  }

  &.registration {
    justify-content: flex-end;

    .border {
      padding: 0 20px 0 0;
    }
  }

  &__links,
  &__hub {
    display: flex;
    max-height: 18px;
    justify-content: center;
    align-items: center;
    overflow: visible;
  }

  &__links {
    display: none;
    padding: 0 27px 0 0;

    a,
    .link {
      font-size: 14px;
      font-weight: 700;
      color: $grey-secondary-text;
      margin-right: 27px;

      &:last-child {
        margin-right: 0;
      }
    }

    > a {
      margin-top: 5px;

      @include laptop {
        transition-duration: 0.5s;

        &:hover {
          color: $white;
        }
      }
    }

    @include laptop {
      display: flex;
      width: fit-content;
    }
  }

  &__language {
    display: flex;
    padding-right: 27px;
    min-width: 30px;
  }
}

$white: #ffffff;
$black: #000000;

// Black
$black-light: #111214;
$black-light-fill: #101010;
$black-lighter: #1a1a1a;
$black-shade: #1d1c1c;
$black-second: #202020;
$black-third: #141517;
$black-fourth: #181818;
$black-fill: #121212;

// Grey
$grey-darker: #2f2f2f;
$grey-dark: #555555;
$grey-dark-second: #444444;
$grey-dark-third: #333333;
$grey-dark-fourth: #494949;
$grey-night: #696969;
$grey-medium: #707070;
$grey-secondary-text: #7e7e7e;
$grey-green: #324537;
$grey-tile: #b4b4b4;
$grey: #818181;
$grey-second: #979797;
$grey-second-dark-bg: #909090;
$grey-light: #acacac;
$grey-light-second: #ababab;
$grey-alto: #d0d0d0;
$grey-third: #f8f8f8;
$grey-fourth: #a8a9a9;
$grey-fifth: #727472;
$grey-dim: #e5e5e5;
$grey-very-light: #f1f1f1;
$grey-tiles: #313131;
$grey-popup: #eeeeee;
$grey-popup-bottom: #e6e6e6;
$grey-ccc: #cccccc;
$grey-f0: #f0f0f0;
$grey-mod-center: #282a28;
$grey-table-bar: #748181;
$grey-table-row: rgba(48, 48, 48, 0.9);
$grey-table-row-odd: rgba(48, 48, 48, 0.5);
$grey-site-preferences: #575757;
$grey-csv-device: rgba(255, 255, 255, 0.08);

// Red
$red: #ff0000;
$red-second: #ff4437;
$red-third: #ff4040;

// Pink
$pink: #ff008c;
$pink-light: #fff2f9;

// Green
$green: #40ff7a;
$green-darker: #177b36;

//Gray
$gray: #888888;

// Yellow
$yellow: #ffd701;

//MYE NEW COLORS
$grey-title: #f0f0f0;
$grey-borders: #333333;
$grey-subtitle: #b1b1b1;
$fb-blue: #1877f2;
$blue-second: #0086ff;

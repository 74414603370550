@mixin label($fontSize: 14px, $lineHeight: 20px, $bottomMargin: 4px, $color: $white) {
  display: block;
  font-size: $fontSize;
  line-height: $lineHeight;
  margin-bottom: $bottomMargin;
  color: $color;
  margin-top: 0;
}

@mixin infoBox($padding: 32px, $paddingMobile: 20px, $bgColor: $black-second, $borderRadius: 24px) {
  width: 100%;
  padding: $paddingMobile;
  background: rgba($white, 0.08);
  border: 1px solid #202020;
  border-radius: 0;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;

  > * {
    grid-column: span 2;
  }

  @include tablet {
    padding: $padding;
    border-radius: $borderRadius;
    margin-bottom: $form-field-margin-bottom;

    > * {
      grid-column: span 1;
    }
  }
}

@mixin infoCard(
  $padding: 32px,
  $paddingMobile: 20px,
  $bgColor: $black-second,
  $borderRadius: 24px
) {
  padding: $paddingMobile;
  background-color: $bgColor;
  border-radius: 24px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin infoSectionLabel(
  $fontSize: 14px,
  $lineHeight: 21px,
  $bottomMargin: 0,
  $color: $grey-secondary-text
) {
  @include label($fontSize, $lineHeight, $bottomMargin, $color);
}

@mixin infoSectionText($fontSize: 16px, $lineHeight: 25px, $color: $white) {
  @include label($fontSize, $lineHeight, 0, $color);
}

@mixin infoSectionTitle(
  $fontSize: 20px,
  $lineHeight: 28px,
  $bottomMargin: $info-box-margin-bottom,
  $color: $white
) {
  @include label($fontSize, $lineHeight, $bottomMargin, $color);

  font-weight: 400;
}

@mixin pageWithInfoBoxes() {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 0;

  @include tablet {
    padding: 50px;
  }
}

@mixin pageWithInfoBoxesContent() {
  width: 100%;
  max-width: 780px;
  animation: grow-container linear 1s;
  transition-duration: 1s;
}

@mixin formFieldGroupLine() {
  display: flex;
  flex-direction: row;
  margin-bottom: $form-field-margin-bottom;
  align-items: flex-end;

  > * {
    margin-bottom: 0;
  }

  > *:not(:last-child) {
    margin-right: $form-field-margin-right;
  }
}

@mixin dataBox() {
  width: 100%;
  padding: 24px;
  background-color: $grey-third;
  border-radius: $borderRadius;
}

@mixin scrollbar($border-color: $white, $background-color: $pink) {
  &::-webkit-scrollbar {
    margin-top: 5px;
    width: 12px;
    background-color: rgba($white, 0.08);
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba($black, 0.3);
    border-radius: 10px;
    background-color: rgba($white, 0.08);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-clip: padding-box;
    background-color: $green;
  }
}

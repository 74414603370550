@use 'shared/scss' as *;

.form-address {
  margin-bottom: 32px;
  transition:
    background-color $transition-default,
    padding $transition-default;

  .form-checkbox {
    margin-bottom: 0;

    label {
      font-size: 14px;
      line-height: 20px;
      color: $grey-medium;

      &:before {
        width: 20px;
        height: 20px;
      }

      &:after {
        width: 22px;
        height: 22px;
      }
    }
  }

  &.manual {
    background-color: $grey-dark-third;
    padding: 24px;
    border-radius: $borderRadiusSmallest;
    border: 1px solid rgba($white, 0.6);

    h5 {
      color: $grey-light;
    }
  }

  &-lookup {
    $root: &;

    position: relative;
    animation: darken 0.5s linear;
    width: 100%;
    margin-bottom: 16px;

    label {
      @include label();
    }

    .info-popup__wrapper {
      display: inline-block;
      width: 34px;
      padding-left: 8px;
      vertical-align: middle;

      .info-popup__box {
        transition: 0s ease-out;
        &.active {
          transition: 0s ease-in;
        }
      }

      .text {
        color: $grey-tile;
      }
    }

    &__input {
      width: 100%;
      border-radius: $borderRadiusSmallest;
      border: 1px solid rgba($white, 0.6);
      background-color: $grey-dark-third;
      padding: 8px 16px;
    }

    input {
      width: 100%;
      font-size: 16px;
      line-height: 25px;
      cursor: auto;
      outline: none;
      padding-top: 0;
      color: $white;

      &::placeholder {
        color: rgba($white, 0.5);
        opacity: 1;
      }
    }

    &.error {
      #{$root}__input {
        border-color: $red-third;
      }
    }

    &__suggestions {
      top: 100%;
      border-radius: $borderRadiusSmallest;
      margin: 0;
      z-index: 3;
      padding: 0;
      max-height: 205px;
      overflow-y: auto;
      position: absolute;
      width: 100%;

      &.active {
        background-color: rgba($white, 0.1);
      }
    }

    &__suggestion {
      width: 100%;
      display: block;
      user-select: none;
      background: $grey-dark-third;
      padding: 12px 16px 9px 17px;
      color: $white;
      min-height: 41px;

      &:hover {
        background-color: $grey-dark;
        cursor: pointer;
      }
    }

    &-manual {
      animation: darken 0.5s linear;

      h5 {
        margin: 0 0 $form-field-margin-bottom;
        font-size: 20px;
        line-height: 31px;
        color: rgba($white, 0.35);
        font-weight: normal;
      }
    }
  }
}

@mixin neonglow-general {
  color: $white;
  > .icon {
    top: 20px;
    right: 20px;
    width: 34px;
    height: 34px;
    &:hover {
      opacity: 0.5;
    }
    &::before {
      background-color: rgba($white, 0.1);
      width: 34px;
      height: 34px;
      border-radius: 50%;
    }

    &:hover {
      transform: none;
    }

    .icon-object {
      width: 20px;
      height: 20px;
    }
  }

  .popup__title {
    color: $white;
    font-size: 28px;
    line-height: 35px;
  }

  .popup__description {
    font-size: 16px;
    line-height: 125%;
    color: $grey-subtitle;
    margin: 16px 0;
  }

  &.error {
    border: 2px solid $red;
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }
}

.neonglow {
  &__green {
    background: radial-gradient(141.54% 141.54% at 33.86% -39.35%, #005219 0%, #282a28 42.54%);
    border: 2px solid $grey-borders;
    position: relative;

    @include neonglow-general;

    &:before {
      content: '';
      position: absolute;
      left: 24px;
      top: -2px;
      width: 50%;
      height: 2px;
      background: $grey-borders;
      background: linear-gradient(
        90deg,
        $grey-borders 0%,
        rgba($green, 0.2) 7%,
        rgba($green, 1) 31%,
        $grey-borders 100%
      );
    }

    &:after {
      content: '';
      position: absolute;
      right: 24px;
      bottom: -2px;
      width: 40%;
      height: 2px;
      background: $grey-borders;
      background: linear-gradient(
        90deg,
        $grey-borders 0%,
        rgba($green, 0.3) 52%,
        $grey-borders 100%
      );
    }
  }

  &__pink {
    background: radial-gradient(141.54% 141.54% at 33.86% -39.35%, #6c1344 0%, #2a2829 42.54%);
    border: 2px solid $grey-borders;
    position: relative;

    @include neonglow-general;

    &:before {
      content: '';
      position: absolute;
      left: 24px;
      top: -2px;
      width: 50%;
      height: 2px;
      background: $grey-borders;
      background: linear-gradient(
        90deg,
        $grey-borders 0%,
        rgba($pink, 0.2) 7%,
        rgba($pink, 1) 31%,
        $grey-borders 100%
      );
    }

    &:after {
      content: '';
      position: absolute;
      right: 24px;
      bottom: -2px;
      width: 40%;
      height: 2px;
      background: $grey-borders;
      background: linear-gradient(
        90deg,
        $grey-borders 0%,
        rgba($pink, 0.3) 52%,
        $grey-borders 100%
      );
    }
  }

  &__red {
    background: radial-gradient(141.54% 141.54% at 33.86% -39.35%, #6c1313 0%, #2a2828 42.54%);
    border: 2px solid $grey-borders;
    position: relative;

    @include neonglow-general;

    &:before {
      content: '';
      position: absolute;
      left: 24px;
      top: -2px;
      width: 50%;
      height: 2px;
      background: $grey-borders;
      background: linear-gradient(
        90deg,
        $grey-borders 0%,
        rgba($red, 0.2) 7%,
        rgba($red, 1) 31%,
        $grey-borders 100%
      );
    }

    &:after {
      content: '';
      position: absolute;
      right: 24px;
      bottom: -2px;
      width: 40%;
      height: 2px;
      background: $grey-borders;
      background: linear-gradient(90deg, $grey-borders 0%, rgba($red, 0.3) 52%, $grey-borders 100%);
    }
  }
}

@use 'shared/scss' as *;

.icon {
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  cursor: pointer;
  position: relative;
  z-index: 1;

  // this will fix the iframe not getting a cursor pointer or onclick effect for icons
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 2;
    cursor: pointer;
  }

  &.icon-calendar {
    top: 0px;
    right: 0px;

    &::before {
      top: -8px;
      left: -8px;
    }
  }
}

.icon-object {
  width: 100%;
  height: 100%;
}

@keyframes darken {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes grow-container {
  from {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    opacity: 1;
  }
}

@keyframes background {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.7;
  }
}

@keyframes spinner {
  0% {
    opacity: 1;
    transform: rotateZ(0);
  }

  50% {
    opacity: 0.5;
    transform: rotateZ(180);
  }

  100% {
    opacity: 1;
    transform: rotateZ(360deg);
  }
}

@keyframes spinner-animation {
  0%,
  100% {
    box-shadow:
      0px -10.4px 0px 0px $green,
      7.2px -7.2px 0 0px $grey,
      10px 0px 0 0px $grey,
      7px 7px 0 0px $grey,
      0px 10px 0 0px $grey,
      -7.2px 7.2px 0 0px $grey,
      -10.4px 0px 0 0px $grey,
      -7.2px -7.2px 0 0px $grey;
  }
  12.5% {
    box-shadow:
      0px -10.4px 0px 0px $grey,
      7.2px -7.2px 0 0px $green,
      10px 0px 0 0px $grey,
      7px 7px 0 0px $grey,
      0px 10px 0 0px $grey,
      -7.2px 7.2px 0 0px $grey,
      -10.4px 0px 0 0px $grey,
      -7.2px -7.2px 0 0px $grey;
  }
  25% {
    box-shadow:
      0px -10.4px 0px 0px $grey,
      7.2px -7.2px 0 0px $grey,
      10px 0px 0 0px $green,
      7px 7px 0 0px $grey,
      0px 10px 0 0px $grey,
      -7.2px 7.2px 0 0px $grey,
      -10.4px 0px 0 0px $grey,
      -7.2px -7.2px 0 0px $grey;
  }
  37.5% {
    box-shadow:
      0px -10.4px 0px 0px $grey,
      7.2px -7.2px 0 0px $grey,
      10px 0px 0 0px $grey,
      7px 7px 0 0px $green,
      0px 10px 0 0px $grey,
      -7.2px 7.2px 0 0px $grey,
      -10.4px 0px 0 0px $grey,
      -7.2px -7.2px 0 0px $grey;
  }
  50% {
    box-shadow:
      0px -10.4px 0px 0px $grey,
      7.2px -7.2px 0 0px $grey,
      10px 0px 0 0px $grey,
      7px 7px 0 0px $grey,
      0px 10px 0 0px $green,
      -7.2px 7.2px 0 0px $grey,
      -10.4px 0px 0 0px $grey,
      -7.2px -7.2px 0 0px $grey;
  }
  62.5% {
    box-shadow:
      0px -10.4px 0px 0px $grey,
      7.2px -7.2px 0 0px $grey,
      10px 0px 0 0px $grey,
      7px 7px 0 0px $grey,
      0px 10px 0 0px $grey,
      -7.2px 7.2px 0 0px $green,
      -10.4px 0px 0 0px $grey,
      -7.2px -7.2px 0 0px $grey;
  }
  75% {
    box-shadow:
      0px -10.4px 0px 0px $grey,
      7.2px -7.2px 0 0px $grey,
      10px 0px 0 0px $grey,
      7px 7px 0 0px $grey,
      0px 10px 0 0px $grey,
      -7.2px 7.2px 0 0px $grey,
      -10.4px 0px 0 0px $green,
      -7.2px -7.2px 0 0px $grey;
  }
  87.5% {
    box-shadow:
      0px -10.4px 0px 0px $grey,
      7.2px -7.2px 0 0px $grey,
      10px 0px 0 0px $grey,
      7px 7px 0 0px $grey,
      0px 10px 0 0px $grey,
      -7.2px 7.2px 0 0px $grey,
      -10.4px 0px 0 0px $grey,
      -7.2px -7.2px 0 0px $green;
  }
}

@use 'shared/scss' as *;

.form-checkbox {
  $this: &;

  margin-bottom: $form-field-margin-bottom;

  > input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  > label {
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: $grey-fourth;
    padding-left: 36px;
    text-align: left;
    cursor: pointer;
    min-height: 28px;
    position: relative;
    padding-top: 2px;
    display: flex;
    flex-direction: column;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 22px;
      height: 22px;
      background-color: transparent;
      border: 2px solid $grey-fourth;
      border-radius: $borderRadiusSmall;
      transition:
        background-color $transition-default,
        border-color $transition-default;
      transition-duration: 0.3s;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      opacity: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.08 2.00183L5.87845 10.7708L1.63581 6.52819' stroke='%2340ff7a' stroke-width='4'/%3E%3C/svg%3E");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      transition: opacity $transition-default;
      transition-duration: 0.3s;
    }

    &.has-description {
      &::before,
      &::after {
        top: 22%;
      }
    }

    > span:nth-child(2) {
      font-size: 12px;
      line-height: 18px;
      color: $grey-medium;
    }
  }

  &.checked {
    > label {
      &::after {
        opacity: 1;
      }
    }
  }

  &.black {
    > label {
      color: rgba($white, 0.7);

      &::before {
        background-color: inherit;
        border: 1px solid $white;
      }

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='13' viewBox='0 0 16 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.08 2.00183L5.87845 10.7708L1.63581 6.52819' stroke='%2340FF7A' stroke-width='3'/%3E%3C/svg%3E");
      }
    }
  }

  &.green {
    > label {
      &::before {
        border-radius: 0;
      }

      &::after {
        background-color: $green;
        background-image: url('../../../../public/svg/check-black.svg');
        border: 1px solid $green;
      }
    }
  }
}

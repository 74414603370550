@use 'shared/scss' as *;

.form-multiselect {
  margin-bottom: $form-field-margin-bottom - 16px;

  > label {
    @include label();
    margin-bottom: 16px;
  }

  > .form-checkbox {
    margin-bottom: 16px;
  }
}

@use 'shared/scss' as *;

.form-switch {
  width: 52px;
  height: 28px;
  flex-shrink: 0;
  background: $grey-light;
  display: flex;
  border-radius: 14px;
  flex-direction: column;

  > input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  > label {
    position: relative;
    display: block;
    color: $grey-night;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    text-align: left;
    cursor: pointer;
    width: 52px;
    height: 28px;
  }

  &.checked {
    background: $pink; // fallback if no color class used

    &.pink {
      background: $pink;
    }
    &.green {
      background: $green;
    }

    > label {
      animation: do-checkbox 0.5s ease;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAcCAYAAACQ0cTtAAACU0lEQVRIib3WvU9TURjH8W8rIeUKooYawmY06SRGEgZX5WUiUBOd/C90V3f9A9icdHAjMdCIYSFhUEPAGghpILEaU3wrRIGG0K9DKSkvLW0p/rZ789zzueeek+dc+I8JVVuodgDngQ6gZffZr0AW+B4KhfInfhu1V32ivvPoLKjP1AG1qV6kQ32q/i6DHExefa721ApdV99WiRzMinqrWiimfqoTKiat9h0HtaivTwgVk1K7K2GPGgQV81JtOQq6oi43GFO9WzTCJV4/cLmqha0tI2pkD1PbgTunAAGMAJ17GHAOuFHPSBsbG0xOTpJKpcqVBMDVUuwihTZUU7a2tpiYmGBqaorV1dVKpbFSLFqpMpPJkMvl9t3b3t4mkUgwNzdHb28v3d3ldzkHPqPlqtbX1xkfH2dsbIydnZ29+9PT08zOzhKLxRgYGKC1tbUSFpRiG+WqIpEI7e3tJJNJEokEAMlkkpmZGaLRKENDQwRBUAkC+AxQ7NIZ4A9w6PWam5sZHBxkc3OT+fl5stksmUyGSCRCPB6nra3tOAhgpXRmv4AP5SqDIGB4eJhoNMri4iLhcJh4PE5XV1c1UA74uHelhtXHx7WCdDrt6OioS0tLtXSQV+rZfbzao2YqPZXP511bW6sFUr1/aK7qGQuHZSPzRj16UdVL6vsGQd/UmxVXU+1Tv5wQyqsPq9k9qLctHO/15If6oCqoBOyx8ANTSybU/pqgErBpd5Yv1PUywM4uck+9UGm8qn5S1YBCs+4ErlE4If4CC8Ay8DMUCq3VNaPTyj+YtLnnjkdbpwAAAABJRU5ErkJggg==');
      background-size: 50% 90%;
      background-repeat: no-repeat;
      background-position: center right;
    }
  }

  &.unchecked {
    > label {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAcCAYAAACQ0cTtAAACsElEQVRIib2WS08TYRSGn5EWLCGtSLGwYGE0YSFWSuJCNlKt5dKNG93gv9C9ca//wcREF66MMZGkFjdNCNqkxjQsECpeKtQL2liCITOvi3akYqcdCvguZ96T55xvznfOwH+U4dYoKQgcAYKArxr7EfgOfDYMw9pzNpLOSrol6YXqa0HSHUlxSZ5WIUFJtyWtO0B2ypJ0V9LIbkFnJD1zCdmpvKQLbkGDknItgmy9lxRrBvJJerJHkK03ksKNYDf3CWTrgSRfPdAJScv7DJOkKzbjUA3vEnDc1YfdnS5LOgzVSy0pADysAv/RzMwMKysrhEIh4vE4Pl/lZFKpFPl8Hr/fTzQaJRgM1gvfAE4ZhvHWvoR+IOKUWkdHB4VCgUKhgCQmJiZIp9PMzc2xublJJBKhu7vbKbwTOAn8gR2lMobqamxsDEmk02kWFxdZXV2lXC5jWRaRSIREIkFbW5tTOMAgkLRhvY2cANFoFNM0mZ+fZ319HUmEw2Gmpqbwer3Nwvtgu0HUzA3Q09ODJAyjMr+9Xi8ej6tx2AlgOzeaubPZLLOzsxiGQXt7O5Zlkc1mARgfH292jO9gu7I14KeTM5fLkUqlKJVK9Pf3Mz09zdDQEKZpkslkSCaTmKbZCJavhX0DMo2qKhaLDAwMkEgkCIVCxGIxhoeH2draYmlpiVKp5BT+C3gN28dYAp4D5+u5w+EwXV1djI6O0ttb6SWPx8Pk5CQ+n49AINCo9R8Dxb+eSBqRtHYA4+qazagdV6+Ae07pWVb9rS/J8R2QBB45BR6T9HKfKvok6ZxTFjYwJunDHkGWpBsNQTXAi6qs91b0RdJ1V6Aa4IgqPzC70VNJdTeHG6CnWuV9SSUHgFmFXJXk2P/g8idVUieVYd0HnKayIcrAArAMfDUM40dLFR2UfgNjcTtu2hw4pQAAAABJRU5ErkJggg==');
      background-size: 50% 90%;
      background-repeat: no-repeat;
      background-position: center left;
      border-color: $grey-night;
    }
  }
}

@keyframes do-checkbox {
  from {
    margin-right: 16px;
  }
  to {
    margin-right: 0;
  }
}

@keyframes un-checkbox {
  from {
    left: 16px;
  }
  to {
    left: 0;
  }
}

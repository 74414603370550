@use 'shared/scss' as *;

.statusbar {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 99;

  .icon {
    width: 18px;
  }

  &__wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    color: $black;
    background-color: $green;
    padding: 16px 0 14px;
    animation: show-status-bar 0.7s;
    animation-timing-function: linear;

    @include mobile-only-xs {
      padding: 16px 0 10px 14px;
    }

    &.error {
      background-color: $red;
      color: $white;
    }
  }

  &__close {
    z-index: 1;
  }

  &__popup {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &__text {
    margin-left: 5px;
    margin-top: 3px;
  }
}

@keyframes show-status-bar {
  from {
    opacity: 0;
    clip: rect(0px, auto, 0px, 0px);
  }
  to {
    opacity: 1;
    clip: rect(0px, auto, 60px, 0px);
  }
}

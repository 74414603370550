@use 'shared/scss' as *;

.ReactModal__Content .statusbar__wrap {
  display: none;
}

// Modal Package Overwride Style
.ReactModal__Overlay {
  background-color: unset !important;
  animation: darken 1s ease;
  overflow: auto;
  padding: 70px 0;
  display: flex;
  background-color: rgba($black, 0.8) !important;

  @include tablet {
    padding: 70px 30px;
  }
}

.ReactModal__Body--open {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.popup {
  $root: &;

  background-color: $white;
  color: $grey-subtitle;
  position: relative;
  width: 100%;
  max-width: 700px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  animation: darken 0.2s linear;
  height: fit-content;
  overflow: visible;
  margin: auto;
  outline: none;

  &__close {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__button {
    outline: none;
    border: none;
    width: fit-content;
    height: auto;
    cursor: pointer;

    .icon {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 42px;
      height: 42px;
      transition-duration: 1s;

      @include tablet {
        top: 30px;
        right: 30px;
      }

      &:hover {
        transform: rotateZ(90deg);
      }
    }
  }

  &__title {
    font-size: 32px;
    line-height: 49px;
    margin: 0;
    max-width: 80%;
    font-weight: normal;

    &-red {
      color: $red;
    }

    @include tablet {
      max-width: 90%;
    }
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    margin: 3px 0 0;
    white-space: pre-line;
  }

  &__content {
    width: 100%;
    height: fit-content;
    padding: 0;
    margin: 0;
  }

  @include tablet {
    border-radius: $borderRadiusBig;
    padding: 32px 50px;
  }

  &.red {
    border: 3px solid $red-second;

    #{$root}__title {
      color: $red-second;
    }
  }

  &.full-content {
    background-color: $grey-popup;
    padding: 0;

    #{$root}__content {
      padding-top: 0;
    }
  }

  &__image {
    $root: &;

    background-color: $white;
    color: $black;
    position: relative;
    width: 100%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    animation: darken 0.2s linear;
    height: fit-content;
    overflow-x: auto;
    overflow-y: hidden;
    margin: auto;

    @include tablet {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &--content {
      width: 100%;
      height: fit-content;
      padding: 20px;
      margin: 0;

      @include tablet {
        padding: 70px 30px;
      }
    }

    &--image {
      width: 100%;
      height: 100%;
      max-height: 150px;
      object-fit: cover;

      @include tablet {
        max-height: 100%;
        max-width: 240px;
      }
    }

    @include tablet {
      border-radius: $borderRadiusBig;
    }

    &.red {
      border: 3px solid $red-second;

      #{$root}__title {
        color: $red-second;
      }
    }
  }
}

.form-error-message {
  color: $red;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 32px;
}
